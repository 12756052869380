import request from "@/api/service";

const api = {
    // 获取欢迎语
    getWelcome:'/hotel/hotelproject/getGreeting',
    // 修改欢迎语
    upWelcome:'/hotel/hotelproject/updateGreeting'
}
//获取欢迎语
export function axios_getWelcome(hotelId){
  return request({
    url:api.getWelcome+`/${hotelId}`,
    method:'get',
  })
}
// 修改欢迎语
export function axios_upWelcome(data){
  return request({
    url:api.upWelcome,
    headers:{"responseType": 'JSON'},
    method:'post',
    data:data
  })
}