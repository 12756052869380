<template>
   <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>AIOT</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管理</el-breadcrumb-item>
           <el-breadcrumb-item>语音设备</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
      <el-row class="content-box">
         <!-- 查询框 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>语音设备：</label>
                  <el-select  class="width-220"  v-model="typeQ" :placeholder="$t('msg.select')" clearable>
                     <el-option v-for="(item, index) in this.dictData['voice-type']" :key="index" :label="item" :value="index"></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <el-input  class="width-220" v-model="condition" :placeholder="$t('msg.device_serial_room')" clearable></el-input>
               </el-row>
               <el-button  class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-search" @click="handleQuery(true)" ><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button  class="m-bottom-5" type="primary" icon="el-icon-refresh-right" @click="handleQuery(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 主体内容 -->
         <el-row class="table-box">
            <!-- 表格按钮 -->
            <el-row class="table-head-btn">
               <el-row class="right-btn">
                  <el-button class="m-right-20 bg-gradient" type="primary" icon="el-icon-setting" @click="handleWelcome">欢迎语配置</el-button >
                  <el-button class="m-bottom-5 bg-gradient" type="primary" icon="el-icon-plus" @click="handleAdd" style="float: right"><span v-text="$t('msg.add_voice')">添加语音</span></el-button>
               </el-row>
            </el-row>
            <!-- 表格 -->
            <el-table v-loading="loading" :data="tableData" border fit highlight-current-row style="width: 100%" :stripe="true">
               <el-table-column :label="$t('msg.number')" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column :label="$t('msg.dev_type')" min-width="150">
                  <template slot-scope="scope">
                     {{ scope.row.type | filterType(that) }}
                  </template>
               </el-table-column>
               <el-table-column prop="serialNum" :label="$t('msg.device_serial_number')" min-width="150"></el-table-column>
               <el-table-column prop="version" :label="$t('msg.device_version')" min-width="110">
                  <template slot-scope="scope">
                     {{ scope.row.version === "HOTEL" ? "酒店版本" : "家庭版本" }}
                  </template>
               </el-table-column>
               <el-table-column prop="hotelName" :label="$t('msg.own_hotel')" min-width="160"></el-table-column>
               <el-table-column prop="roomNo" :label="$t('msg.device_room')" min-width="110"></el-table-column>
               <!-- <el-table-column prop="refUserName" :label="$t('msg.bind_username')" min-width="110"></el-table-column> -->
               <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="150" sortable></el-table-column>
               <el-table-column prop="updateTime" :label="$t('msg.update_time')" min-width="150" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" :min-width="operWidth" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-edit" @click="handleEdit(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-button  type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                        <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
         </el-row>
         <!-- 新增/编辑语音设备 -->
         <el-dialog :title="action === 'add' ? '新增语音设备' : $t('msg.edit_voice')" :visible.sync="isShow" width="550px" :before-close="handleClose">
            <el-form class="form-dialog-box">
               <el-form-item label="酒店名称">
                  <el-input class="width-300" disabled :value="hotelName"></el-input>
               </el-form-item>
               <el-form-item required label="楼栋/单元">
                  <el-cascader
                     :key="buildKey"
                     v-model="buildIDs"
                     :options="buildings"
                     @change="changeBuilding"
                     clearable
                     class="width-300"></el-cascader>
               </el-form-item>
               <el-form-item required label="房间号">
                  <el-select  class="width-300" v-model="roomId" filterable :placeholder="$t('msg.select')" clearable>
                     <el-option
                              v-for="item in rooms"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item required label="语音设备">
                  <el-select  class="width-300" v-model="type" :placeholder="$t('msg.select')" disabled>
                     <el-option v-for="(item, index) in this.dictData['voice-type']" :key="index" :label="item" :value="index"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item required label="序列号">
                  <el-input  class="width-300" v-model="serialNum" :placeholder="$t('msg.device_serial_number')" clearable></el-input>
               </el-form-item>
               <el-form-item label="设备openId">
                  <el-input  class="width-300" v-model="openId" :placeholder="$t('msg.device_openId')" clearable></el-input>
               </el-form-item>
               <!-- <el-form-item style="width:100%" required label="设备版本">
                  <template>
                     <el-radio v-model="version" label="HOTEL"><span v-text="$t('msg.hotel_version')">酒店版本</span></el-radio>
                     <el-radio v-model="version" label="HOME"><span v-text="$t('msg.home_version')">家庭版本</span></el-radio>
                  </template>
               </el-form-item> -->
               <!-- <el-form-item  required label="绑定用户">
                  <el-select  class="width-300" v-model="userId" placeholder="请选择" clearable>
                     <el-option
                              v-for="item in voiceUsers"
                              :key="item.id"
                              :label="item.userName"
                              :value="item.id">
                     </el-option>
                  </el-select>
               </el-form-item> -->
               <el-form-item label="备注信息">
                  <el-input class="width-300 type-textarea" type="textarea" v-model="remark" :placeholder="$t('msg.inp_remark_info')"></el-input>
               </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
               <el-button  @click="handleReset()" v-text="$t('msg.reset')">重置</el-button>
               <el-button  type="primary" @click="handleSave()" v-text="$t('msg.save')">保存</el-button>
            </span>
         </el-dialog>
         <!-- 欢迎语配置 -->
         <el-dialog title="欢迎语配置" :visible.sync="dialogVisible" width="30%" class="welcome">
         <el-input  type="textarea" resize="none" :autosize="{ minRows: 5, maxRows: 5}" v-model="greeting" placeholder="请输入欢迎语"></el-input>
         <div class="m-top-10">说明:{userName}:代表入住客人姓名。</div>
         <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="upWelcome">确 定</el-button>
         </span>
         </el-dialog>
      </el-row>
   </section>
</template>
<script>
   import {axios_getWelcome,axios_upWelcome} from "@/api/aiot/project/voiceEquipment";
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   export default {
      data () {
         return {
            that: this,
            tableData: [],      // 表格数据
            buildings: [],      // 楼栋列表
            rooms: [],          // 房间列表
            voiceUsers: [],     // 语音用户列表
            loading: true,      // 表格加载效果
            total: 0,           // 数据总数
            boxIcon: true,      // 侧边栏显示隐藏
            isShow: false,      // 是否显示弹窗
            action: '',         // 操作行为
            hotelName: '',      // 酒店名称
            buildIDs: [],       // 楼栋ID
            buildKey: '',       // 联级器索引
            operWidth: 0,       // 编辑栏宽度
            /* 查询列表参数 */
            limit: 1,           // 每页数
            page: 1,            // 当前页
            hotelId: '',        // 酒店ID
            typeQ: '',          // 语音设备类型
            condition: '',      // 设备序列号
            /* 提交表单字段 */
            //hotelId: '',      // 酒店ID
            id: '',             // 语音设备ID
            roomId: '',         // 房间ID
            type: 'DUEROS',     // 语音设备类型
            serialNum: '',      // 设备序列号
            openId: '',         // 设备openId
            version: 'HOTEL',   // 设备版本
            // userId: '',         // 语音用户
            remark: '',         // 备注信息

            dialogVisible:false,
            greeting:'',

            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            add_success: '添加成功！',
            update_success: '修改成功！',
            prompt: '提示！',
            confirm_remove: '确定移除？',
            remove_success: '移除成功！',
            sel_hotel: '请选择酒店！',
            select_room_no: '请选择房间号！',
            select_type_no: '请选择设备类型！',
            serialNum_no: '设备序列号不能为空！',
            inp_serial_number: '请输入序列号！',
         }
      },
      computed:{
         ...mapState(['hotelInfo','dictData'])
      },
      mounted () {
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.hotelId = this.hotelInfo.id
         this.hotelName = this.hotelInfo.hotelName
         getDict(['voice-type'])
         this.getVoiceList()
      },
      methods: {
         // 获取语音设备列表
         getVoiceList() {
            const url = urlObj.getVoicePages
            const param = {
               limit: this.limit,
               page: this.page,
               hotelId: this.hotelId,
               type: this.typeQ,
               condition: this.condition
            }
            this.$axios.post(url, param).then(async res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索语音
         handleQuery(bool){
            if (bool) return this.getVoiceList()
            this.typeQ = ''
            this.condition = ''
         },
         // 改变每页数
         pageChange(num) {
            this.limit = num
            this.getVoiceList()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getVoiceList()
         },
         // 获取楼栋列表
         getBuildings(){
            return new Promise(((resolve,reject) => {
               const url = urlObj.buildAndUnitList + `/${this.hotelId}`
               this.$axios.get(url, {}).then(res => {
                  if (res.success) {
                     let buildings = res.records
                     this.buildings = buildings.map(item1 => {
                        let children = []
                        if (item1.buildUnits.length !== 0){
                           children = item1.buildUnits.map(item2 => {
                              return {value: item2.id, label: item2.unitName}
                           })
                        }
                        return {value: item1.id, label: item1.buildName, children}
                     })
                     return resolve(res)
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }))
         },
         // 改变当前楼栋
         changeBuilding(val) {
            this.getRooms(val[1])
         },
         // 获取房间号列表
         getRooms(buildUnitId){
            const url = urlObj.getRoomLists
            const param = {
               hotelId: this.hotelId,
               buildUnitId
            }
            this.$axios.post(url, param).then((res) => {
               if (res.success) {
                  let rooms = res.records
                  this.rooms = rooms.map(item => {
                     return {value: item.id, label: item.roomNo}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 获取智能语音用户列表
         getVoiceUsers(){
            const url = urlObj.voiceUsers
            const param = {
               hotelId: this.hotelId,
               page: 1,
               limit: 50,
            }
            this.$axios.post(url, param).then(res => {
               if (res.success){
                  let voiceUsers = res.records
                  this.voiceUsers = voiceUsers.map(item => {
                     return {id: item.id, userName: item.userName}
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 添加语音
         handleAdd(){
            this.isShow = true
            this.action = 'add'
            this.getBuildings()
            // this.getVoiceUsers()
         },
         // 编辑语音
         async handleEdit(row){
            this.isShow = true
            this.action = 'edit'
            await this.getBuildings()
            this.getRooms()
            // this.getVoiceUsers()
            // 回显表单
            // 回显楼栋/单元
            let buildings = []
            this.buildings.forEach(item =>{
               if(item.hasOwnProperty('children')){
                  item.children.forEach(items => {
                     if(row.unitId === items.value) buildings = [item.value,items.value]
                  })
               }
            })
            this.buildIDs = buildings
            this.id = row.id
            this.roomId = row.roomId
            this.type = row.type
            this.serialNum = row.serialNum
            this.openId = row.openUid
            this.version = row.version
            // this.userId = row.userId
            this.remark = row.remark
         },
         // 重置表单
         handleReset(){
            this.buildIDs = []
            this.roomId = ''
            this.type = 'DUEROS'
            this.serialNum = ''
            this.openId = ''
            this.version = 'HOTEL'
            // this.userId = ''
            this.remark = ''
         },
         // 保存操作
         handleSave(){
            // 重置表单
            if (!this.hotelId) {
              this.$alert(this.sel_hotel, '', {
                confirmButtonText: this.confirm,
                type: 'error'
              })
              return
            }
            if (!this.roomId) {
               this.$alert(this.select_room_no, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.type) {
               this.$alert(this.select_type_no, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            if (!this.serialNum){
               this.$alert(this.serialNum_no, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return
            }
            let url = urlObj.addHotelVoice
            let param = {
               hotelId: this.hotelId,
               roomId: this.roomId,
               type: this.type,
               serialNum: this.serialNum,
               openUid: this.openId,
               version: this.version,
               userId: this.userId,
               remark: this.remark
            }
            // 如果类型为小度 则无需绑定用户
            this.type === 'DUEROS' && delete param.userId
            if (this.action === 'edit') {
               url = urlObj.editHotelVoice
               param.id = this.id
            }
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.action === 'add' ? this.add_success : this.update_success,
                     type: 'success'
                  })
                  this.getVoiceList()
                  this.handleClose()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 关闭弹窗
         handleClose(){
            this.isShow = false
            this.handleReset()
         },
         // 删除语音设备
         handleDel(id){
            this.$confirm(this.confirm_remove, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deleteHotelVoice + `/${id}`
               this.$axios.get(url, {}).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getVoiceList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
         // 获取欢迎语
         getWelcome(){
            axios_getWelcome(this.hotelId).then(res=>{
               if(res.records){
                  this.greeting = res.records.greeting;
               }else{
                  this.greeting = '';
               }
            })
         },
         // 设置欢迎语
         handleWelcome(){
            this.dialogVisible = true;
            this.getWelcome();
         },

         // 修改欢迎语
         upWelcome(){
            let data = {
               id:this.hotelId,         //酒店ID 
               greeting:this.greeting   //欢迎语
            }
            axios_upWelcome(data).then(res=>{
               if(res.success){
                  this.dialogVisible = false;
                  this.$message({
                     showClose: true,
                     message: '配置成功',
                     type: 'success'
                  })
               }
            })
         },
      },
      filters: {
         filterType(val, that) {
            if (val && that.dictData['voice-type']) return that.dictData['voice-type'][val]
         }
      },
      watch:{
         hotelInfo:{
            handler(newVal,oldVal){
               if(newVal.id !== oldVal.id && oldVal){
                  this.hotelId = this.hotelInfo.id
                  this.hotelName = this.hotelInfo.hotelName
                  this.getVoiceList()
               }
            }
         },
         buildings(newVal){
            if(newVal.length !== 0) this.buildKey = newVal[0].value
         }
      }
   }
</script>

<style scoped lang="scss">
.welcome{
  ::v-deep .el-dialog__body{
    padding: 10px 20px 0;
  }
}
</style>
